import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ShopifyContext } from '../contexts/ShopifyContext';

export default class LineItem extends React.PureComponent {
  state = {
    isUpdating: false,
  }

  render() {
    const { item } = this.props;

    return (
      <ShopifyContext.Consumer>
        {context => (
          <Container isUpdating={this.state.isUpdating && context.isBasketUpdating}>
            <ImageContainer>
              {item.variant.image && (
                <img src={item.variant.image.src} alt={item.variant.image.altText} />
              )}
            </ImageContainer>

            <DetailsWrapper>
              <Details>
                <p>{item.title}</p>
                <p>£{item.variant.price * item.quantity}</p>
              </Details>

              <QuantitySelector>
                <QuantityButton onClick={() => {
                  context.updateLineItem(item.id, item.quantity - 1);
                  this.setState({ isUpdating: true });
                  }}
                >
                  <svg width="10px" height="10px" viewBox="0 0 10 10">
                    <g stroke="#B2B2B2" strokeWidth="2">
                      <line x1="0" y1="5" x2="10" y2="5" />
                    </g>
                  </svg>
                </QuantityButton>
                {item.quantity}
                <QuantityButton onClick={() => {
                  context.updateLineItem(item.id, item.quantity + 1);
                  this.setState({ isUpdating: true });
                  }}
                >
                  <svg width="10px" height="10px" viewBox="0 0 10 10">
                    <g stroke="#B2B2B2" strokeWidth="2">
                      <line x1="0" y1="5" x2="10" y2="5" />
                      <line x1="5" y1="0" x2="5" y2="10" />
                    </g>
                  </svg>
                </QuantityButton>
              </QuantitySelector>
            </DetailsWrapper>

            <Delete onClick={() => {
              context.removeLineItem(item.id);
              this.setState({ isUpdating: true });
              }}
            >
              <svg width="10px" height="10px" viewBox="0 0 10 10">
                <g stroke="white" strokeWidth="2">
                  <line x1="1" y1="1" x2="9" y2="9" />
                  <line x1="1" y1="9" x2="9" y2="1" />
                </g>
              </svg>
            </Delete>
          </Container>
        )}
      </ShopifyContext.Consumer>
    );
  }
}

// const LineItem = ({
//   item, updateLineItem, removeLineItem, lineItemsLength,
// }) => (
//   <Container>
//     <ImageContainer>
//       <img src={item.variant.image.src} alt={item.variant.image.altText} />
//     </ImageContainer>

//     <DetailsWrapper>
//       <Details>
//         <p>{item.title}</p>
//         <p>£{item.variant.price * item.quantity}</p>
//       </Details>

//       <QuantitySelector>
//         <QuantityButton onClick={() => updateLineItem(item.id, item.quantity - 1)}>
//           <svg width="10px" height="10px" viewBox="0 0 10 10">
//             <g stroke="#B2B2B2" strokeWidth="2">
//               <line x1="0" y1="5" x2="10" y2="5" />
//             </g>
//           </svg>
//         </QuantityButton>
//         {item.quantity}
//         <QuantityButton onClick={() => updateLineItem(item.id, item.quantity + 1)}>
//           <svg width="10px" height="10px" viewBox="0 0 10 10">
//             <g stroke="#B2B2B2" strokeWidth="2">
//               <line x1="0" y1="5" x2="10" y2="5" />
//               <line x1="5" y1="0" x2="5" y2="10" />
//             </g>
//           </svg>
//         </QuantityButton>
//       </QuantitySelector>
//     </DetailsWrapper>

//     <Delete onClick={() => removeLineItem(item.id)}>
//       <svg width="10px" height="10px" viewBox="0 0 10 10">
//         <g stroke="white" strokeWidth="2">
//           <line x1="1" y1="1" x2="9" y2="9" />
//           <line x1="1" y1="9" x2="9" y2="1" />
//         </g>
//       </svg>
//     </Delete>
//   </Container>
// );

// export default LineItem;


// Animations
const Updating = keyframes`
  50% {
    opacity: 0.5;
  }
`;

// Styles
const Container = styled.div`
  padding: 20px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  width: 100%;
  max-width: 350px;
  display: flex;
  font-size: 16px;
  position: relative;
  /* flex-shrink: 0; */
  flex: none;
  margin: 0 0 10px;
  /* opacity: ${props => props.isDeleting && 0.3};
  transition: opacity 300ms; */
  animation: ${props => props.isUpdating && `${Updating} 1200ms infinite`};

  &:last-child {
    margin: 0;
  }

  @media(min-width: 700px) {
    margin: 0 5px 10px;
    
    &:last-child {
      margin: 0 5px 10px;
    }
  }

  @media (min-width: 900px) {
    margin: 0 10px 0 0;
    width: 350px;
    font-size: 26px;
    
    &:first-child {
      margin-left: auto;

      &:before {
        content: '';
        width: 40px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 100%;
        pointer-events: none;
      }
    }

    &:last-child {
      margin-right: auto;

      &:after {
        content: '';
        width: 40px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 100%;
        pointer-events: none;
      }
    }
  }

  /* &:not(:only-of-type) {
    width: 170px;
    display: block;
    font-size: 16px;
  } */
`;

const ImageContainer = styled.div`
  width: 145px;
  height: 100px;
  /* padding: 10px; */
  background-color: #F2F2F2;
  margin-right: 20px;
  flex: none;

  @media (min-width: 900px) {
    height: 145px;
    /* padding: 20px; */
  }

  /* ${Container}:not(:only-of-type) & {
    padding: 10px;
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
  } */

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const DetailsWrapper = styled.div`
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  /* ${Container}:not(:only-of-type) & {
    text-align: center;
  } */

  p {
    line-height: 1.1;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Details = styled.div`
  flex: 1;

  /* ${Container}:not(:only-of-type) & {
    margin-bottom: 10px;
  } */
`;

const QuantitySelector = styled.div`
  background-color: #f2f2f2;
  padding: 8px;
  border-radius: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 16px;

  /* ${Container}:not(:only-of-type) & {
    padding: 5px;
  } */
`;

const QuantityButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  transition: background-color 200ms;

  svg {
    display: block;
  }

  &:hover {
    background-color: white;
  }
`;

const Delete = styled.button`
  position: absolute;
  top: -6px;
  right: -6px;
  background-color: #FF4949;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  border: none;
  color: white;
  padding: 5px;
  outline: none;
  cursor: pointer;
  

  @media (min-width: 900px) {
    opacity: 0;
    transform: scale(0.8);
    transition: transform 150ms, opacity 100ms;

    ${Container}:hover & {
      opacity: 1;
      transform: scale(1);
    }
  }

  svg {
    display: block;
  }
`;
