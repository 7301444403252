import React, { Component } from 'react';
import styled from 'styled-components';

export default class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = { countDown: 5 };
  }

  componentDidMount() {
    const countDown = setInterval(() => {
      this.setState(prevState => ({
        countDown: prevState.countDown - 1,
      }));

      if (this.state.countDown < 1) {
        this.props.history.push('/');
        clearInterval(countDown);
      }
    }, 1000);
  }

  render() {
    return (
      <Container>
        <span>Juliana Futter</span>
        <Name>
404: Not found Redirecting in
{' '}
{this.state.countDown}
</Name>
      </Container>
    );
  }
}

// Styles
const Container = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: top;
  padding: 10px;
  padding: calc(10px + env(safe-area-inset-top))
    calc(10px + env(safe-area-inset-right))
    calc(10px + env(safe-area-inset-bottom))
    calc(10px + env(safe-area-inset-left));
  transform: translateZ(0);
  font-size: 20px;
  line-height: 1;
  color: #ff4949;
  text-transform: uppercase;
`;

const Name = styled.span`
  font-size: 40px;
  margin-top: -0.1em;
  border-bottom: 0.1em solid;
`;
