import React from 'react';
import styled, { keyframes } from 'styled-components';
// import CloseDot from './CloseDot';
import { ShopifyContext } from '../contexts/ShopifyContext';

const MobileCartButton = () => (
  <ShopifyContext.Consumer>
    {(context) => {
      if (context.cart) {
        const getCartTotal = () => context.cart.lineItems.reduce((acc, cur) => acc + cur.quantity, 0);

        return (
          <Container
            visible={
              context.cart.lineItems.length > 0
              || context.isBasketVisible
              || context.isBasketUpdating
            }
            cartIsOpen={context.isBasketVisible}
            onClick={context.showBasket}
          >
            <Text>Cart</Text>
            <Counter
              isUpdating={context.isBasketUpdating}
              hasUpdated={context.hasUpdated}
            >
              {!context.isBasketUpdating && getCartTotal()}
            </Counter>
          </Container>
        );
      }
    }}
  </ShopifyContext.Consumer>
);

export default MobileCartButton;

// Animations
const Particles = keyframes`
  30% {
    opacity: 1;
  }
  
  100% {
    opacity: 0;
    transform: scale(0.8);
    box-shadow: 
    0em -0.8em 0 -0.2em #ff4949,
    0.6em -0.6em 0 -0.2em #ff4949,
    0.8em 0em 0 -0.2em #ff4949,
    0.6em 0.6em 0 -0.2em #ff4949,
    0em 0.8em 0 -0.2em #ff4949,
    -0.6em 0.6em 0 -0.2em #ff4949,
    -0.8em 0em 0 -0.2em #ff4949,
    -0.6em -0.6em 0 -0.2em #ff4949
  }
`;

const Bump = keyframes`
  60% { transform: scale(1.2) }
`;

const Updating = keyframes`
  50% {
    opacity: 0;
    transform: scale(0.8);
  }
`;

// Styles
const Container = styled.div`
  font-size: 16px;
  line-height: 1;
  color: white;
  background-color: #333333;
  border-radius: 30px;
  display: flex;
  align-items: top;
  padding: 10px 10px 10px 15px;
  cursor: pointer;
  position: fixed;
  left: 50%;
  bottom: 77px;
  /* bottom: calc(55px + (22px + env(safe-area-inset-bottom))); */
  z-index: 100;

  transform: ${props => (props.visible ? "translateX(-50%)" : "translate(-50%, 50%)")};
  opacity: ${props => (props.visible ? 1 : 0)};
  pointer-events: ${props => (!props.visible || props.cartIsOpen) && 'none'};
  transition: opacity 200ms, transform 300ms;

  @media (min-width: 900px) {
    display: none;
  }
`;

const Text = styled.span`
  display: block;
  margin-right: 10px;
`;

const Counter = styled.div`
  display: flex;
  height: 20px;
  width: 20px;
  font-size: 12px;
  line-height: 1;
  border-radius: 50%;
  background-color: #ff4949;
  color: white;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  text-align: center;
  position: relative;
  animation: ${props => props.isUpdating && `${Updating} 1200ms infinite`};
  animation: ${props => props.hasUpdated && `${Bump} 300ms ease`};

  svg {
    display: block;
  }

  &::after {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 50%;
    font-size: 40px;
    content: "";
    animation: ${props => props.hasUpdated && `${Particles} 1000ms ease`};
    box-shadow: 0 0 0 -0.2em #ff4949, 0 0 0 -0.2em #ff4949, 0 0 0 -0.2em #ff4949,
      0 0 0 -0.2em #ff4949, 0 0 0 -0.2em #ff4949, 0 0 0 -0.2em #ff4949,
      0 0 0 -0.2em #ff4949, 0 0 0 -0.2em #ff4949;
  }
`;
