import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import Helmet from 'react-helmet';
import Markdown from 'markdown-to-jsx';
import ProjectPageImage from './ProjectPageImage';
import ProjectContent2Up from './ProjectContent2Up';
import ProjectContentFullWidth from './ProjectContentFullWidth';
import Header from './Header';
import MobileHeader from './MobileHeader';
import TabBar from './TabBar';

import { ContentfulContext } from '../contexts/ContentfulContext';

export default class ProjectPage extends Component {
  state = {
    titleOpacity: 1,
  };

  componentDidMount() {
    this.handleScroll();
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleScroll);
  }

  handleScroll = () => {
    const windowCenter = window.innerHeight * 0.75;
    const viewTop = window.pageYOffset;
    this.setState({ titleOpacity: 1 - viewTop / windowCenter });
  };

  render() {
    return (
      <ContentfulContext.Consumer>
        {({ projects }) => {
          if (!projects) {
            return (
              <Container>
                <Header />
                <MobileHeader backLink="/" />
                <TabBar />
                <Title>
                  <BigTitle>
                    <SkeletonText width="5" />
                    <SkeletonText width="0.5" />
                    <SkeletonText width="2" />
                  </BigTitle>
                </Title>
                <Helmet>
                  <title>Loading - Juliana Futter</title>
                </Helmet>
              </Container>
            );
          }

          // Get active project and extract data from it
          const {
            title,
            description,
            textColor,
            backgroundColor,
            subtitle,
            subtitlePosition,
            content,
          } = projects.filter(
            project => project.fields.slug === this.props.match.params.slug,
          )[0].fields;

          return (
            <Container color={textColor}>
              <Helmet>
                <title>
{title}
{' '}
- Juliana Futter
</title>
              </Helmet>

              <Section backgroundColor={backgroundColor}>
                <Clip>
                  <Header color={textColor} backgroundColor={backgroundColor} />
                  <Top titleOpacity={this.state.titleOpacity}>{title}</Top>
                  <MobileHeader
                    color={textColor}
                    backgroundColor={backgroundColor}
                    backLink="/"
                  />
                  <TabBar color={textColor} backgroundColor={backgroundColor} />
                </Clip>

                <Content>
                  <Title opacity={this.state.titleOpacity}>
                    {subtitle && subtitlePosition === 'above' && (
                      <Subtitle>{subtitle}</Subtitle>
                    )}
                    <BigTitle>{title}</BigTitle>
                    {subtitle && subtitlePosition === 'below' && (
                      <Subtitle>{subtitle}</Subtitle>
                    )}
                  </Title>

                  {content
                    && content.map((item) => {
                      if (item.fields.moduleType === 'full width') {return (
                          <ProjectContentFullWidth
                            color={textColor}
                            media={item.fields.media}
                          />
                        );}
                      if (item.fields.moduleType === '2 up') {return (
                          <ProjectContent2Up
                            color={textColor}
                            media={item.fields.media}
                          />
                        );}
                      return (
                        <ProjectPageImage
                          color={textColor}
                          media={item.fields.media}
                        />
                      );
                    })}
                </Content>
              </Section>

              {description && (
                <Section>
                  <Clip>
                    <Header color={textColor} background="white" />
                    <Top titleOpacity={0}>{title}</Top>
                    <MobileHeader
                      color={textColor}
                      backgroundColor="white"
                      backLink="/"
                    />
                    <TabBar color={textColor} />
                  </Clip>

                  <Info>
                    <Markdown options={markdownOptions}>{description}</Markdown>
                  </Info>
                </Section>
              )}
            </Container>
          );
        }}
      </ContentfulContext.Consumer>
    );
  }
}

const Container = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  color: ${({ color }) => color};

  *::selection {
    background-color: ${({ color }) => color};
  }
`;

const Content = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding-top: calc(100vh - 77px);
  padding-top: calc(100vh - (55px + 22px + env(safe-area-inset-bottom)));
  padding-bottom: 20vw;
`;

const Top = styled.header`
  width: 100vw;
  position: fixed;
  top: 40px;
  left: 0;
  text-align: center;
  font-size: 26px;
  line-height: 1;
  font-weight: 500;
  z-index: 210;
  text-transform: uppercase;
  opacity: ${({ titleOpacity }) => (titleOpacity < 0.3 ? 1 : 0)};
  transition: opacity 300ms;
  pointer-events: none;

  @media (max-width: 900px) {
    display: none;
  }
`;

const Title = styled.div`
  text-align: center;
  line-height: 0.8;
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  padding: 56px 20px 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: ${({ opacity }) => opacity};

  @media (min-width: 900px) {
    padding: 40px;
    height: 100vh;
  }
`;

const BigTitle = styled.p`
  font-size: 40px;
  text-transform: uppercase;
  margin: 10px 0 15px;
  max-width: 9em;

  @media (min-width: 600px) {
    font-size: 60px;
  }
  @media (min-width: 900px) {
    font-size: 80px;
  }
  @media (min-width: 1100px) {
    font-size: 100px;
  }
  @media (min-width: 1400px) {
    font-size: 120px;
  }
`;

const Subtitle = styled.p`
  font-size: 16px;
  line-height: 1;
  text-transform: none;
  margin: 0;

  @media (min-width: 900px) {
    font-size: 26px;
  }
`;

const Info = styled.div`
  min-height: 100vh;
  width: 100%;
  padding: 56px 20px 77px;
  padding: 56px calc(20px + env(safe-area-inset-right))
    calc(57px + 20px + env(safe-area-inset-bottom))
    calc(20px + env(safe-area-inset-left));
  background-color: white;

  @media (min-width: 900px) {
    padding: 120px 40px;
  }
`;

const Description = styled.p`
  line-height: 1;
  margin: 0 auto;
  font-size: 26px;
  margin: 0 0 20px;

  @media (min-width: 600px) {
    font-size: 40px;
    margin: 0 0 40px;
    line-height: 0.9;
  }

  @media (min-width: 900px) {
    font-size: 60px;
  }
  @media (min-width: 1200px) {
    font-size: 70px;
  }
  @media (min-width: 1400px) {
    font-size: 80px;
  }
  &:last-child {
    margin: 0;
  }
`;

const Section = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor || 'white'};
  width: 100%;
  padding: 0px;
  position: relative;
`;

const Clip = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  z-index: 100;

  ${CSS.supports(
    '(clip-path: inset(0 0 0 0)) or ( -webkit-clip-path: inset(0 0 0 0))',
  )
    ? 'clip-path: inset(0 0 0 0);'
    : 'clip: rect(0, auto, auto, 0);'}
`;

const Gradient = keyframes`
  0% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
`;

const SkeletonText = styled.div`
  display: inline-block;
  height: calc(1em / 1.5);
  width: ${({ width }) => `${width}em` || '3em'};
  margin: 0 0.15em 0 0;
  padding: 0;
  background: linear-gradient(
      to right,
      #ededed 0%,
      #ededed 30%,
      #f4f4f4 50%,
      #ededed 70%,
      #ededed 100%
    )
    repeat;
  background-size: 500% 100%;
  background-position: 100% 50%;
  animation: ${Gradient} 3s ease infinite;
`;

// Markdown
const markdownOptions = {
  forceBlock: true,
  overrides: {
    p: Description,
  },
};
