// Libraries
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { injectGlobal } from 'styled-components';

// Components
import ProjectPage from './ProjectPage';
import Index from './Index';
import About from './About';
import Shop from './Shop';
import ProductPage from './ProductPage';
import NotFound from './NotFound';
// import Loading from './Loading';

// Context Providers
import { ShopifyProvider } from '../contexts/ShopifyContext';
import { ContentfulProvider } from '../contexts/ContentfulContext';

// Assets
import FoundersWoff2 from '../assets/FoundersGroteskWeb-Regular.woff2';
import FoundersWoff from '../assets/FoundersGroteskWeb-Regular.woff';
import FoundersEot from '../assets/FoundersGroteskWeb-Regular.eot';

const App = () =>
// if (!this.state.products || !this.state.projects) {
//   return <Loading />;
// }

  (
    <ShopifyProvider>
      <ContentfulProvider>

        <Switch>
          <Route exact path="/" component={Index} />
          <Route path="/projects/:slug" component={ProjectPage} />
          <Route path="/about" component={About} />
          <Route exact path="/shop" component={Shop} />
          <Route path="/shop/:slug" component={ProductPage} />
          <Route component={NotFound} />
        </Switch>

      </ContentfulProvider>
    </ShopifyProvider>
  );

export default App;

// Styles
injectGlobal`
  @font-face {
    font-family: Founders;
    font-weight: 400;
    font-style: normal;
    font-display: block;
    src:  url(${FoundersWoff2}) format('woff2'),
          url(${FoundersWoff}) format('woff'),
          url(${FoundersEot}) format('embedded-opentype');
  }

  html, body, #root {
    height: 100%;
  }

  body {
    /* min-height: 100vh; */
    font-family: Founders, sans-serif;
    margin: 0;
    -webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    letter-spacing: 0.03em;
    height: 100%;
  }

  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-overflow-scrolling: touch;
  }

  ::selection {
    background-color: #FF4949;
    color: white;
  }
`;

