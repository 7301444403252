import React, { Component } from 'react';
import styled from 'styled-components';

export default class ProjectContentFullWidth extends Component {
  state = {
    isInView: false,
    isLoaded: false,
  };

  componentDidMount() {
    this.handleScroll();
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleScroll);
  }

  handleScroll = () => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) this.setState({ isInView: true });
    });
    observer.observe(this.el);
  }

  render() {
    return (
      <ImageWrapper
        innerRef={el => this.el = el}
        isInView={this.state.isInView}
        isLoaded={this.state.isLoaded}
        color={this.props.color}
      >
        {this.props.media.map(media => (
          <figure>
            <img
              srcSet={`${media.fields.file.url}?w=600&h=600 400w, ${media.fields.file.url}?w=1000&h=1000 600w, ${media.fields.file.url} 1000w`}
              src={media.fields.file.url}
              alt={media.fields.title}
              onLoad={() => this.setState({ isLoaded: true })}
            />
            {media.fields.description && <figcaption>{media.fields.description}</figcaption>}
          </figure>
        ))}
      </ImageWrapper>
    );
  }
}

const ImageWrapper = styled.div`
  width: 100vw;
  display: flex;
  z-index: 10;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-bottom: 20vw;
  padding: 0 20px;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 900px) {
    flex-direction: row;
    padding: 0 40px;
  }

  figure {
    position: relative;
    margin: 0;
    background-color: ${props => props.color};
    opacity: ${props => !props.isInView && 0};
    transform: ${props => !props.isInView && 'translate(0, 40px)'};
    transition: all 300ms;
  }

  figcaption {
    position: absolute;
    top: 100%;
    left: 0;
    text-align: center;
    width: 100%;
    font-size: 12px;
    margin: 5px 0 0;

    @media (min-width: 900px) {
      margin: 10px 0 0;
      font-size: 16px;
    }
  }

  img {
    display: block;
    height: auto;
    max-width: 100%;
    opacity: ${props => !props.isLoaded && 0};
    transition: opacity 300ms;
  }
`;

// const HiResImg = styled.img`
//   display: block;
//   margin: 0;
//   height: auto;
//   max-width: 100%;
//   transition: opacity 200ms;
//   /* display: ${props => (props.isLoaded ? 'block' : 'none')}; */
//   /* opacity: ${props => (!props.isLoaded && 0)}; */
// `;
