import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ShopifyContext } from '../contexts/ShopifyContext';
import shopClient from '../service/shopClient';

const { imageForSize } = shopClient.image.helpers;

class ProductThumbnail extends React.PureComponent {
  state = {
    imageLoaded: false,
  }

  handleLoad = () => {
    this.setState({ imageLoaded: true });
  }

  render() {
    const {
      handle, images, title, variants,
    } = this.props.product;

    const image = imageForSize(images[0], { maxWidth: 1500, maxHeight: 750 });

    return (
      <Container>
        <ImageContainer>
          <ImageLink to={`/shop/${handle}`}>
            <Image src={image} onLoad={this.handleLoad} hasLoaded={this.state.imageLoaded} />
          </ImageLink>

          <ImageOverlay>
            <StyledLink to={`/shop/${handle}`}>
              <Button>See Details</Button>
            </StyledLink>

            <ShopifyContext.Consumer>
              {({ addToCart }) => <Button onClick={() => addToCart(variants[0].id, 1)}>Add to Cart</Button>}
            </ShopifyContext.Consumer>
          </ImageOverlay>

        </ImageContainer>
        <StyledLink to={`/shop/${handle}`}>
          <Description>{title} – £{variants[0].price}</Description>
        </StyledLink>
      </Container>
    );
  }
}

export default ProductThumbnail;

const Container = styled.div`
  margin: 0 0 20px;
  font-size: 16px;
  line-height: 1;
  color: #333333;
  text-decoration: none;
  width: calc(50% - 5px);

  &:nth-child(odd) {
    /* margin-left: 20px; */
  }

  @media (min-width: 900px) {
    font-size: 26px;
    margin: 0 0 40px;
    width: calc(50% - 10px);
  }
`;

const ImageContainer = styled.div`
  background-color: #F2F2F2;
  position: relative;
  /* width: calc(50vw - 25px); */
  width: 100%;
  /* width: calc(50vw - max(20px, env(safe-area-inset-right))); */
  height: calc(50vw - 25px);
  max-height: 50vh;
  

  @media (min-width: 900px) {
    /* width: calc(50vw - 30px); */
    height: calc(50vw - 30px);
  }
`;

const ImageLink = styled(Link)`
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  /* padding: 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  display: block;
  opacity: ${props => !props.hasLoaded && 0};
  transition: opacity 300ms;
  /* margin: 20px; */

  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ImageOverlay = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.2);
  /* background-color: rgba(220,220,220,0.8); */
  opacity: 0;
  z-index: 10;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: opacity 300ms;
  text-align: center;
  pointer-events: none;

  ${ImageContainer}:hover & {
    opacity: 1;
  }

  @media (min-width: 900px) {
    display: flex;
  }
`;

const Description = styled.p`
  margin: 10px 0 0;
  display: inline-block;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const Button = styled.div`
  border-radius: 5px;
  background-color: white;
  padding: 9px 15px 11px;
  width: 160px;
  transition: background-color 300ms, color 300ms;
  z-index: 1000;
  position: relative;
  pointer-events: all;
  cursor: pointer;

  &:not(:first-child) {
    margin-top: 10px;
  }

  &:hover {
    background-color: #333333;
    color: white;
  }
`;
