import React, { Component } from 'react';
import contentClient from '../service/contentClient';

export const ContentfulContext = React.createContext();

export class ContentfulProvider extends Component {
  state = {
    projects: null,
    about: null,
  }

  async componentDidMount() {
    const projectsInOrder = await contentClient.getEntries({
      content_type: 'projectOrder',
      include: 2,
    });
    // const projects = await contentClient.getEntries({
    //   content_type: 'project',
    //   order: 'sys.createdAt',
    // });
    const about = await contentClient.getEntries({ content_type: 'about' });

    this.setState({
      // projects: projects.items,
      projects: projectsInOrder.items[0].fields.projects,
      about: about.items[0].fields,
    });
  }

  render() {
    return (
      <ContentfulContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </ContentfulContext.Provider>
    );
  }
}
