import React from "react";
import styled, { keyframes } from "styled-components";
import { NavLink } from "react-router-dom";
import { ShopifyContext } from "../contexts/ShopifyContext";

const TabBar = props => (
  <Container color={props.color} backgroundColor={props.backgroundColor}>
    <Link exact to="/">
      Index
    </Link>
    <Link to="/about">About</Link>

    <ShopifyContext.Consumer>
      {context => (
        <Link
          to="/shop"
          hasProducts={context.cart && context.cart.lineItems.length > 0}
        >
          Shop
        </Link>
      )}
    </ShopifyContext.Consumer>
  </Container>
);

export default TabBar;

const Bounce = keyframes`
  40% { transform: translate(0, -100%) }
  60% { transform: translate(0) }
`;

const Container = styled.footer`
  pointer-events: all;
  position: fixed;
  /* transform: translate(0%, 1px); */
  bottom: 0;
  left: 0;
  z-index: 70;
  padding: 18px 0 22px;
  padding-bottom: calc(22px + (env(safe-area-inset-bottom) / 2));
  width: 100%;
  display: flex;
  justify-content: space-around;
  font-size: 16px;
  line-height: 1;
  color: ${props => props.color || "#B3B3B3"};
  border-top: 1px solid ${props =>
    props.color ? `${props.color}16` : "#33333316"};
  background-color: ${props => props.backgroundColor || "white"};

  /* @supports (backdrop-filter: blur(10px)) {
    backdrop-filter: blur(15px);
    background-color: ${props =>
      props.backgroundColor ? `${props.backgroundColor}cc` : "#ffffffcc"};
  } */

  @media (min-width: 900px) {
    display: none;
  }
`;

const Link = styled(NavLink)`
  cursor: pointer;
  transition: color 300ms;
  position: relative;
  text-decoration: none;
  text-align: center;
  color: inherit;
  pointer-events: all;

  &:hover,
  &.active {
    color: #333333;
  }

  /* &.active {
    pointer-events: none;
  } */

  &:not(.active)::after {
    content: "";
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background-color: #ff4949;
    position: absolute;
    top: 0;
    left: 100%;
    opacity: ${props => !props.hasProducts && 0};
    transform: ${props => !props.hasProducts && "translateY(100%)"};
    transition: opacity 300ms, transform 300ms;
  }

  &:hover::after {
    animation: ${Bounce} 700ms infinite;
  }
`;
