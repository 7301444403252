import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const MobileHeader = props => (
  <Container color={props.color} backgroundColor={props.backgroundColor}>
    {props.children ? (
      props.children
    ) : (
      <StyledLink to={props.backLink}>
        <svg width="6px" height="10px" viewBox="0 0 6 10">
          <polyline
            strokeWidth="1"
            fill="none"
            stroke={props.color || '#B3B3B3'}
            points="5 1 1 5 5 9"
          />
        </svg>
        {props.backText || 'Back'}
      </StyledLink>
    )}
    <span>
© Juliana Futter
{' '}
{new Date().getFullYear()}
</span>
  </Container>
);

export default MobileHeader;

// Styles
const Container = styled.header`
  pointer-events: all;
  padding: 20px;
  padding:
    20px
    calc(20px + env(safe-area-inset-right))
    20px
    calc(20px + env(safe-area-inset-left));
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 1;
  color: ${props => props.color || '#B3B3B3'};
  position: fixed;
  transform: translate(0%, 1px);
  top: -1px;
  left: 0;
  z-index: 50;
  background-color: ${props => props.backgroundColor || 'white'};

  /* @supports (backdrop-filter: blur(10px)) {
    backdrop-filter: blur(15px);
    background-color: ${props => (props.backgroundColor ? `${props.backgroundColor}cc` : "#ffffffcc")};
  } */


  @media (min-width: 900px) {
    display: none;
  }
`;

const StyledLink = styled(Link)`
  margin: 0 10px 0 0;
  cursor: pointer;
  transition: color 300ms;
  position: relative;
  text-decoration: none;
  color: inherit;

  svg {
    display: inline-block;
    margin: 0 5px 0 0;
  }
`;
