import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

export default class IndexItem extends Component {
  state = {
    isHovering: false,
    isLoaded: false,
  };

  render() {
    const { handleMouseEnter, handleMouseLeave, hideImage } = this.props;
    const {
      slug, title, textColor, thumbnail,
    } = this.props.project.fields;

    return (
      <Container
        textColor={textColor}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Link to={`/projects/${slug}`}>
          <figure>
            <LowResImg
              src={`${thumbnail.fields.file.url}?w=10&h=10`}
              isLoaded={this.state.isLoaded}
              alt={thumbnail.fields.file.title}
            />
            <HiResImg
              src={`${thumbnail.fields.file.url}?w=200&h=200`}
              onLoad={() => this.setState({ isLoaded: true })}
              isLoaded={this.state.isLoaded}
              alt={thumbnail.fields.file.title}
              hideImage={hideImage}
            />
          </figure>
          {title}
        </Link>
      </Container>
    );
  }
}

// Styles
const bounce = keyframes`
  40% { transform: translate(0, -20%) }
  60% { transform: translate(0) }
`;

const Container = styled.div`
  font-size: 40px;
  line-height: 1;
  color: #333333;
  cursor: pointer;
  display: inline;
  margin-right: 0.4em;

  *::selection {
    background-color: ${props => props.textColor};
  }

  @media (min-width: 600px) {
    font-size: 60px;
  }

  @media (min-width: 900px) {
    font-size: 80px;
  }

  @media (min-width: 1100px) {
    font-size: 100px;
    line-height: 0.95;
  }

  @media (min-width: 1400px) {
    font-size: 110px;
  }

  a {
    color: rgba(0,0,0,0.8);
    text-decoration: none;
    transition: color 200ms;
  }

  figure {
    display: inline-block;
    background-color: #EDEDED;
    margin: 0;
    height: calc(1em / 1.5);
    padding: 0;
    margin-right: 0.15em;
    position: relative;
  }

  &:hover a { color: ${props => props.textColor}; }
  &:hover figure { animation: ${bounce} 700ms infinite; }
  /* &:hover img { opacity: 1; } */
`;

const LowResImg = styled.img`
  display: ${props => (props.isLoaded ? 'none' : 'block')};
  height: calc(1em / 1.5); 
  margin: 0;
  opacity: 0;
  transition: opacity 200ms;
`;

const HiResImg = styled.img`
  display: ${props => (props.isLoaded ? 'block' : 'none')};
  display: block;
  height: calc(1em / 1.5); 
  margin: 0;
  opacity: ${props => ((props.hideImage || !props.isLoaded) && 0)};
  transition: opacity 200ms;

  ${Container}:hover & {
    opacity: 1;
  }
`;
