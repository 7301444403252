import React from 'react';
import styled, { keyframes } from 'styled-components';

const LoadingIndexItem = () => (
  <Container>
    <Block width="0.5" />
    <Block width="1.5" />
    <Block width="4.5" />
    <Block width="1.5" />
    <Block width="3" />

    <Block width="0.8" />
    <Block width="3.2" />
    <Block width="1" />
    <Block width="0.8" />
    <Block width="1.5" />

    <Block width="1" />
    <Block width="3.5" />
    <Block width="2" />

    <Block width="0.8" />
    <Block width="2" />
    <Block width="2" />

    <Block width="1" />
    <Block width="1" />
    <Block width="1.5" />
    <Block width="3" />
  </Container>
);

export default LoadingIndexItem;

// Styles
const Gradient = keyframes`
  0% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
`;

const Container = styled.div`
  font-size: 40px;
  line-height: 1;
  pointer-events: none;

  @media (min-width: 600px) {
    font-size: 60px;
  }

  @media (min-width: 900px) {
    font-size: 80px;
  }

  @media (min-width: 1100px) {
    font-size: 100px;
    line-height: 0.95;
  }

  @media (min-width: 1400px) {
    font-size: 110px;
  }
`;

const Block = styled.div`
  display: inline-block;
  height: calc(1em / 1.5);
  width: ${props => `${props.width}em` || '3em'};
  margin: 0 0.15em 0 0;
  padding: 0;

  background:
    linear-gradient(
      to right,
      #EDEDED 0%,
      #EDEDED 30%,
      #F4F4F4 50%,
      #EDEDED 70%,
      #EDEDED 100%
    ) repeat;
    background-size: 500% 100%;
  background-position: 100% 50%;
  animation: ${Gradient} 3s ease infinite;
`;
