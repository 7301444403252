import 'babel-polyfill';
import 'intersection-observer';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App';
import ScrollToTop from './components/ScrollToTop';
import registerServiceWorker from './registerServiceWorker';
import GA from './service/GoogleAnalytics';

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop>
      { GA.init() && <GA.RouteTracker /> }
      <App />
    </ScrollToTop>
  </BrowserRouter>,
  document.getElementById('root'),
);
registerServiceWorker();
