import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import Header from './Header';
import MobileHeader from './MobileHeader';
import IndexItem from './IndexItem';
import LoadingIndexItem from './LoadingIndexItem';
import TabBar from './TabBar';

import { ContentfulContext } from '../contexts/ContentfulContext';

export default class Index extends React.Component {
  state = {
    hideImages: false,
  };

  itemMouseEnter = () => {
    this.setState({ hideImages: true });
  };

  itemMouseLeave = () => {
    this.setState({ hideImages: false });
  };

  render() {
    return (
      <Container>
        <Helmet>
          <title>Index - Juliana Futter</title>
        </Helmet>
        <Header />
        <MobileHeader>Index</MobileHeader>

        <ContentfulContext.Consumer>
          {context => (
            <React.Fragment>
              {context.projects ? (
                context.projects.map(project => (
                  <IndexItem
                    key={project.fields.slug}
                    project={project}
                    handleMouseEnter={this.itemMouseEnter}
                    handleMouseLeave={this.itemMouseLeave}
                    hideImage={this.state.hideImages}
                  />
                ))
              ) : (
                <LoadingIndexItem />
              )}
            </React.Fragment>
          )}
        </ContentfulContext.Consumer>

        <TabBar />
      </Container>
    );
  }
}

// Styles
const Container = styled.div`
  width: 100%;
  padding: 56px 20px 77px;
  padding: 56px calc(20px + env(safe-area-inset-right))
    calc(55px + 22px + env(safe-area-inset-bottom))
    calc(20px + env(safe-area-inset-left));

  @media (min-width: 900px) {
    padding: 106px 40px 40px;
  }
`;
