import React from 'react';
import styled, { keyframes } from 'styled-components';
import Helmet from 'react-helmet';
import Markdown from 'markdown-to-jsx';
import Header from './Header';
import MobileHeader from './MobileHeader';
import TabBar from './TabBar';
import { ContentfulContext } from '../contexts/ContentfulContext';

const About = () => (
  <Container textColor="#FF4949" backgroundColor="#FCECEC">
    <Helmet>
      <title>About - Juliana Futter</title>
    </Helmet>
    <Header color="#FF4949" backgroundColor="#FCECEC" />
    <MobileHeader color="#FF4949" backgroundColor="#FCECEC">
      About
    </MobileHeader>
    <TabBar color="#FF4949" backgroundColor="#FCECEC" />

    <Content>
      <ContentfulContext.Consumer>
        {context => (context.about ? (
            <React.Fragment>
              <Bio>
                <Markdown options={{ forceBlock: true }}>
                  {context.about.bio}
                </Markdown>
              </Bio>
              <Columns>
                <Links>
                  <Heading>Has worked for</Heading>
                  <Markdown options={{ forceBlock: true }}>
                    {context.about.links}
                  </Markdown>
                </Links>
                <Contact>
                  <Heading>Contact</Heading>
                  <ul>
                    {context.about.email && (
                      <li>
                        Email:{" "}
                        <a href={`mailto:${context.about.email}`}>
                          {context.about.email}
                        </a>
                      </li>
                    )}
                    {context.about.phone && (
                      <li>
                        Phone:{" "}
                        <a
                          href={`tel:${context.about.phone.replace(
                            /\s+/g,
                            "-"
                          )}`}
                        >
                          {context.about.phone}
                        </a>
                      </li>
                    )}
                    {context.about.instagram && (
                      <li>
                        Instagram:{" "}
                        <a
                          href={`http://instagram.com/${
                            context.about.instagram
                          }`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          @{context.about.instagram}
                        </a>
                      </li>
                    )}
                  </ul>
                </Contact>
              </Columns>
            </React.Fragment>
          ) : (
            <Bio>
              <SkeletonBio width="3" />
              <SkeletonBio width="2.5" />
              <SkeletonBio width="1" />
              <SkeletonBio width="0.5" />
              <SkeletonBio width="5" />
              <SkeletonBio width="1" />
              <SkeletonBio width="3.5" />
              <SkeletonBio width="3.5" />
              <SkeletonBio width="3" />
              <SkeletonBio width="1" />
              <SkeletonBio width="3.5" />
              <SkeletonBio width="2" />
              <SkeletonBio width="2" />
              <SkeletonBio width="3.5" />
              <SkeletonBio width="4.5" />
              <SkeletonBio width="3" />
              <SkeletonBio width="2" />
              <SkeletonBio width="3.5" />
              <SkeletonBio width="4" />
              <SkeletonBio width="1" />
              <SkeletonBio width="3" />
              <SkeletonBio width="2.5" />
              <SkeletonBio width="1" />
              <SkeletonBio width="0.5" />
              <SkeletonBio width="5" />
              <SkeletonBio width="1" />
              <SkeletonBio width="3.5" />
              <SkeletonBio width="3.5" />
              <SkeletonBio width="3" />
              <SkeletonBio width="1" />
              <SkeletonBio width="3.5" />
              <SkeletonBio width="2" />
              <SkeletonBio width="2" />
              <SkeletonBio width="3.5" />
              <SkeletonBio width="4.5" />
              <SkeletonBio width="3" />
              <SkeletonBio width="2" />
            </Bio>
          ))
        }
      </ContentfulContext.Consumer>
    </Content>

    <Credit>
      Site by
{" "}
      <a href="https://homestud.io/" target="_blank" rel="noopener noreferrer">
        Home Studio
      </a>
    </Credit>
  </Container>
);

export default About;

// Styles
const Container = styled.div`
  background-color: ${props => props.backgroundColor};
  color: ${props => props.textColor};
  display: flex;
  min-height: 100%;
  flex-direction: column;
  padding: 56px 20px 77px;
  padding: 56px calc(20px + env(safe-area-inset-right))
    calc(55px + 22px + env(safe-area-inset-bottom))
    calc(20px + env(safe-area-inset-left));

  @media (min-width: 900px) {
    padding: 106px 40px 40px;
  }
`;

const Content = styled.div`
  flex: 1;
`;

const Bio = styled.div`
  font-size: 26px;
  line-height: 1;
  margin: 0 0 20px;

  @media (min-width: 600px) {
    font-size: 40px;
    margin: 0 0 40px;
    line-height: 0.9;
  }

  @media (min-width: 900px) {
    font-size: 60px;
  }

  @media (min-width: 1200px) {
    font-size: 70px;
  }

  @media (min-width: 1400px) {
    font-size: 80px;
  }

  p {
    margin: 0 0 20px;

    &:last-child {
      margin: 0;
    }
  }
`;

const Columns = styled.div`
  font-size: 16px;
  line-height: 1;
  margin-bottom: 40px;

  a {
    color: #333333;
    text-decoration: none;
    transition: opacity 300ms;

    &:hover {
      opacity: 0.3;
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    margin: 0;

    @media (min-width: 900px) {
      margin: 0;
    }
  }

  @media (min-width: 900px) {
    display: flex;
    flex: 1;
    flex-direction: row-reverse;
    font-size: 26px;
  }
`;

const Links = styled.div`
  flex: 1;
  margin-bottom: 20px;

  p {
    margin: 0;
    color: #333333;
    max-width: 18em;
  }
`;

const Contact = styled.div`
  flex: 1;
`;

const Heading = styled.h3`
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  margin: 0 0 10px;

  @media (min-width: 900px) {
    font-size: 26px;
  }
`;

const Credit = styled.div`
  font-size: 12px;

  a {
    color: #333333;
    text-decoration: none;
    transition: opacity 300ms;

    &:hover {
      opacity: 0.3;
    }
  }

  @media (min-width: 600px) {
    font-size: 16px;
  }
`;

const Gradient = keyframes`
  0% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
`;

const SkeletonBio = styled.div`
  display: inline-block;
  height: calc(1em / 1.5);
  width: ${props => `${props.width}em` || '3em'};
  margin: 0 0.15em 0 0;
  padding: 0;
  background: linear-gradient(
      to right,
      #fd9a9a 0%,
      #fd9a9a 30%,
      #fcbbbb 50%,
      #fd9a9a 70%,
      #fd9a9a 100%
    )
    repeat;
  background-size: 500% 100%;
  background-position: 100% 50%;
  animation: ${Gradient} 3s ease infinite;
`;
