import React from "react";
import styled, { keyframes } from "styled-components";
import Helmet from "react-helmet";
import Header from "./Header";
import MobileHeader from "./MobileHeader";
import ProductThumbnail from "./ProductThumnail";
import CartButton from "./CartButton";
import MobileCartButton from "./MobileCartButton";
import TabBar from "./TabBar";
import Cart from "./Cart";

import { ShopifyContext } from "../contexts/ShopifyContext";

const Shop = () => (
  <Container>
    <Helmet>
      <title>Shop - Juliana Futter</title>
    </Helmet>

    <Header backgroundColor="#ffffff">
      <CartButton />
    </Header>
    <MobileHeader>Shop</MobileHeader>
    <MobileCartButton />
    <TabBar />
    <Cart />

    <Products>
      <ShopifyContext.Consumer>
        {context => {
          if (!context.products) {
            return (
              <React.Fragment>
                <SkeletonProduct>
                  <SkeletonImage />
                </SkeletonProduct>
                <SkeletonProduct>
                  <SkeletonImage />
                </SkeletonProduct>
                <SkeletonProduct>
                  <SkeletonImage />
                </SkeletonProduct>
                <SkeletonProduct>
                  <SkeletonImage />
                </SkeletonProduct>
                <SkeletonProduct>
                  <SkeletonImage />
                </SkeletonProduct>
                <SkeletonProduct>
                  <SkeletonImage />
                </SkeletonProduct>
                <SkeletonProduct>
                  <SkeletonImage />
                </SkeletonProduct>
                <SkeletonProduct>
                  <SkeletonImage />
                </SkeletonProduct>
              </React.Fragment>
            );
          }

          return context.products.map(product => (
            <ProductThumbnail key={product.id} product={product} />
          ));
        }}
      </ShopifyContext.Consumer>
    </Products>
  </Container>
);

export default Shop;

// Styles
const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  padding: 56px 20px 57px;
  padding: 56px calc(20px + env(safe-area-inset-right))
    calc(55px + 22px + env(safe-area-inset-bottom))
    calc(20px + env(safe-area-inset-left));

  @media (min-width: 900px) {
    padding: 106px 20px 20px;
  }
`;

const Products = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const SkeletonProduct = styled.div`
  margin: 0 0 20px;
  font-size: 16px;
  line-height: 1;
  width: calc(50% - 5px);

  @media (min-width: 900px) {
    font-size: 26px;
    margin: 0 0 40px;
    width: calc(50% - 10px);
  }
`;

const Gradient = keyframes`
  0% { background-position: 100% 50% }
  100% { background-position: 0% 50% }
`;

const SkeletonImage = styled.div`
  width: 100%;
  height: calc(50vw - 25px);
  max-height: 50vh;
  background: linear-gradient(
      to right,
      #ededed 0%,
      #ededed 30%,
      #f4f4f4 50%,
      #ededed 70%,
      #ededed 100%
    )
    repeat;
  background-size: 500% 100%;
  background-position: 100% 50%;
  animation: ${Gradient} 3s ease infinite;

  @media (min-width: 900px) {
    height: calc(50vw - 30px);
  }
`;
