import React, { Component } from 'react';
import shopClient from '../service/shopClient';

export const ShopifyContext = React.createContext();

export class ShopifyProvider extends Component {
  state = {
    cart: null,
    products: null,
    isBasketVisible: false,
    isBasketUpdating: false,
    hasUpdated: false,
  }

  async componentDidMount() {
    // Load products
    const products = await shopClient.product.fetchAll();
    this.setState({ products });

    // Load existing checkout
    if (localStorage.checkoutId) {
      const { checkoutId } = localStorage;

      try {
        const cart = await shopClient.checkout.fetch(checkoutId);

        if (!cart.completedAt) {
          this.setState({ cart });
          return;
        }
      } catch (error) {
        console.log(error);
      }
    }

    // If no existing checkout or error
    this.createCheckout();
  }

  createCheckout = async () => {
    const cart = await shopClient.checkout.create({});
    this.setState({ cart });
    localStorage.setItem('checkoutId', cart.id);
  }

  addToCart = async (variantObject, quantity) => {
    this.setState({ isBasketUpdating: true });

    const lineItemsToAdd = { variantId: variantObject, quantity: parseInt(quantity, 10) };
    const cart = await shopClient.checkout.addLineItems(this.state.cart.id, lineItemsToAdd);
    this.setState({
      cart,
      isBasketUpdating: false,
      hasUpdated: true,
    });

    setTimeout(() => this.setState({ hasUpdated: false }), 1000);
  }

  removeLineItem = async (variantId) => {
    this.setState({ isBasketUpdating: true });

    const cart = await shopClient.checkout.removeLineItems(this.state.cart.id, variantId);
    this.setState({
      cart,
      isBasketUpdating: false,
      hasUpdated: true,
    });

    setTimeout(() => this.setState({ hasUpdated: false }), 1000);
  }

  updateLineItem = async (variantId, quantity) => {
    this.setState({ isBasketUpdating: true });

    const cart = await shopClient.checkout.updateLineItems(this.state.cart.id, { id: variantId, quantity });
    this.setState({
      cart,
      isBasketUpdating: false,
      hasUpdated: true,
    });

    setTimeout(() => this.setState({ hasUpdated: false }), 1000);
  }

  showBasket = () => this.setState({ isBasketVisible: true });
  hideBasket = () => this.setState({ isBasketVisible: false });

  render() {
    return (
      <ShopifyContext.Provider value={{
        addToCart: this.addToCart,
        removeLineItem: this.removeLineItem,
        updateLineItem: this.updateLineItem,
        showBasket: this.showBasket,
        hideBasket: this.hideBasket,
        ...this.state,
      }}
      >
        {this.props.children}
      </ShopifyContext.Provider>
    );
  }
}
