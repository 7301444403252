import React from 'react';
import styled, { keyframes } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { ShopifyContext } from '../contexts/ShopifyContext';

const Header = props => (
  <Container color={props.color} backgroundColor={props.backgroundColor}>
    <Nav>
      <Link exact to="/">Index</Link>
      <Link to="/about">About</Link>

      <ShopifyContext.Consumer>
        {context => (
          <Link to="/shop" hasProducts={context.cart && (context.cart.lineItems.length > 0)}>Shop</Link>
        )}
      </ShopifyContext.Consumer>

    </Nav>
    {props.children}
    <span>© Juliana Futter {new Date().getFullYear()}</span>
  </Container>
);

export default Header;

const Bounce = keyframes`
  40% { transform: translate(0, -100%) }
  60% { transform: translate(0) }
`;

const Container = styled.header`
  pointer-events: all;
  display: none;
  transform: translate(0%, 1px);
  position: fixed;
  top: -1px;
  left: 0;
  z-index: 200;
  padding: 20px;
  width: 100%;
  font-size: 16px;
  line-height: 1;
  color: ${props => props.color || '#B3B3B3'};
  background-color: ${props => props.backgroundColor || '#ffffff'};
  font-size: 26px;
  padding: 40px;

  @media (min-width: 900px) {
    display: flex;
    justify-content: space-between;
  }
`;

const Nav = styled.nav`
  display: flex;
`;

const Link = styled(NavLink)`
  margin: 0 10px 0 0;
  cursor: pointer;
  transition: color 300ms;
  position: relative;
  text-decoration: none;
  color: inherit;

  &:hover, &.active {
    color: #333333;
  }

  /* &.active {
    pointer-events: none;
  } */

  &:not(.active)::after {
    content: '';
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: #FF4949;
    position: absolute;
    top: 0;
    left: 100%;
    opacity: ${props => !props.hasProducts && 0};
    transform: ${props => !props.hasProducts && 'translateY(100%)'};
    transition: opacity 300ms, transform 300ms;
  }

  &:hover::after { animation: ${Bounce} 700ms infinite; }
`;
