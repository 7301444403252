import React from 'react';
import styled, { keyframes } from 'styled-components';
import { ShopifyContext } from '../contexts/ShopifyContext';

const CartButton = () => (
  <ShopifyContext.Consumer>
    {(context) => {
      const getCartTotal = () => context.cart.lineItems.reduce((acc, cur) => acc + cur.quantity, 0);

      if (context.cart) {
        return (
          <Container
            onClick={context.showBasket}
            cartIsOpen={context.isBasketVisible}
            visible={(context.cart.lineItems.length > 0) || context.isBasketVisible || context.isBasketUpdating}
          >
            Cart
            <Counter isUpdating={context.isBasketUpdating} hasUpdated={context.hasUpdated}>
              {!context.isBasketUpdating && getCartTotal()}
            </Counter>
          </Container>
        );
      }
    }}
  </ShopifyContext.Consumer>
);

export default CartButton;

// Animations
const Particles = keyframes`
  30% {
    opacity: 1;
  }
  
  100% {
    opacity: 0;
    transform: scale(0.8);
    box-shadow: 
      0em -0.8em 0 -0.2em #ff4949,
      0.6em -0.6em 0 -0.2em #ff4949,
      0.8em 0em 0 -0.2em #ff4949,
      0.6em 0.6em 0 -0.2em #ff4949,
      0em 0.8em 0 -0.2em #ff4949,
      -0.6em 0.6em 0 -0.2em #ff4949,
      -0.8em 0em 0 -0.2em #ff4949,
      -0.6em -0.6em 0 -0.2em #ff4949;
  }
`;

const Bump = keyframes`
  60% { transform: scale(1.2) }
`;

const Updating = keyframes`
  50% {
    opacity: 0;
    transform: scale(0.9);
  }
`;

// Styles
const Container = styled.div`
  display: flex;
  font-size: 26px;
  line-height: 1;
  color: #333;
  align-items: center;
  cursor: pointer;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: ${props => (props.visible ? 1 : 0)};
  pointer-events: ${props => ((!props.visible || props.cartIsOpen) && 'none')};
  transition: opacity 300ms;
`;

const Counter = styled.div`
  display: flex;
  height: 20px;
  width: 20px;
  font-size: 16px;
  line-height: 1;
  border-radius: 50%;
  background-color: #FF4949;
  margin-left: 5px;
  color: white;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  text-align: center;
  position: relative;
  animation: ${props => (props.isUpdating && `${Updating} 1200ms infinite`)};
  animation: ${props => (props.hasUpdated && `${Bump} 300ms ease`)};

  &::after {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 50%;
  font-size: 40px;
  content: '';
  animation: ${props => (props.hasUpdated && `${Particles} 1000ms ease`)};
  box-shadow: 
    0 0 0 -0.2em #ff4949,
    0 0 0 -0.2em #ff4949,
    0 0 0 -0.2em #ff4949,
    0 0 0 -0.2em #ff4949,
    0 0 0 -0.2em #ff4949,
    0 0 0 -0.2em #ff4949,
    0 0 0 -0.2em #ff4949,
    0 0 0 -0.2em #ff4949;
  }
`;
