import React from 'react';
import styled from 'styled-components';
import LineItem from './LineItem';
import { ShopifyContext } from '../contexts/ShopifyContext';

const Cart = () => (
  <ShopifyContext.Consumer>
    {context => (
      <React.Fragment>
        <Container isVisible={context.isBasketVisible}>
          <LineItems>
            {context.cart && context.cart.lineItems.length < 1 && (
              <EmptyMessage onClick={context.hideBasket}>
                Your cart is empty
                <br />
                Go buy something
              </EmptyMessage>
            )}
            {context.cart
              && context.cart.lineItems.map(item => (
                <LineItem key={item.id} item={item} />
              ))}
          </LineItems>

          {context.cart && context.cart.lineItems.length > 0 && (
            <Summary>
              <CheckoutButton
                href={context.cart && context.cart.webUrl}
                target="_blank"
              >
                Go to Checkout
{" "}
                <span>
£
{context.cart && context.cart.totalPrice}
</span>
              </CheckoutButton>
              <Disclaimer>Shipping is calculated at checkout</Disclaimer>
            </Summary>
          )}
        </Container>
        <Overlay
          onClick={context.hideBasket}
          isVisible={context.isBasketVisible}
        />
      </React.Fragment>
    )}
  </ShopifyContext.Consumer>
);

export default Cart;

const Container = styled.div`
  position: fixed;
  top: 80px;
  opacity: ${props => !props.isVisible && 0};
  transform: ${props => !props.isVisible && 'translateY(100%)'};
  transition: transform 400ms
    ${props => !props.isVisible && ', opacity 0ms 400ms'};
  left: 0;
  width: 100%;
  height: calc(100% - 80px);
  z-index: 100;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 10px 10px 0 0;

  @media (min-width: 900px) {
    top: 106px;
    height: auto;
    border-radius: 0;
    transform: ${props => !props.isVisible && 'translateY(-100%)'};
  }
`;

const LineItems = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #f2f2f2;
  padding: 20px;
  flex: 1;
  align-items: center;
  overflow-y: auto;
  flex-direction: row;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;

  @media (min-width: 900px) {
    padding: 6px 40px 20px;
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: flex-start;
  }
`;

const Summary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-bottom: calc(20px + env(safe-area-inset-bottom));

  @media (min-width: 900px) {
    padding: 20px 40px;
  }
`;

const Disclaimer = styled.p`
  color: #b2b2b2;
  font-size: 12px;
  margin: 10px 0 0;
  width: 100%;
  max-width: 350px;
  text-align: center;
  line-height: 1;
`;

const CheckoutButton = styled.a`
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 20px;
  font-size: 26px;
  line-height: 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 350px;
  text-decoration: none;
  color: rgba(51, 51, 51, 0.5);
  transition: color 300ms, background-color 300ms;

  span {
    color: #333333;
    transition: color 300ms;
  }

  &:hover {
    background-color: #333333;
    color: rgba(255, 255, 255, 0.5);

    span {
      color: #ffffff;
    }
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* background-color: rgba(242, 242, 242, 0.8); */
  background-color: rgba(220, 220, 220, 0.8);
  z-index: 80;
  cursor: pointer;
  transition: opacity 500ms;
  opacity: ${props => !props.isVisible && 0};
  pointer-events: ${props => !props.isVisible && 'none'};
`;

const EmptyMessage = styled.p`
  color: #b3b3b3;
  margin: 20px 0;
  cursor: pointer;
  font-size: 16px;
  line-height: 1.2;

  @media (min-width: 900px) {
    margin: 0 auto 20px;
  }
`;
