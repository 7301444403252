import React, { Component } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import Header from './Header';
import MobileHeader from './MobileHeader';
import CartButton from './CartButton';
import MobileCartButton from './MobileCartButton';
import TabBar from './TabBar';
import Cart from './Cart';
import { ShopifyContext } from '../contexts/ShopifyContext';
import shopClient from '../service/shopClient';

const { imageForSize } = shopClient.image.helpers;

export default class ProductPage extends Component {
  state = {
    detailsIsOpen: false,
  };

  toggleDetails = () => {
    this.setState({ detailsIsOpen: !this.state.detailsIsOpen });
  };

  render() {
    const createMarkup = input => ({ __html: input });

    return (
      <Container>
        <Header background="white">
          <CartButton />
        </Header>
        <MobileHeader backLink="/shop" />
        <MobileCartButton />
        <Cart />
        <TabBar />

        <ShopifyContext.Consumer>
          {(context) => {
            if (!context.products) {
              return (
                <React.Fragment>
                  <Helmet>
                    <title>Loading - Juliana Futter</title>
                  </Helmet>
                  <ImageWrapper />
                </React.Fragment>
              );
            }

            // Get active product and extract data from it
            const {
              title,
              images,
              variants,
              descriptionHtml,
            } = context.products.filter(
              product => product.handle === this.props.match.params.slug,
            )[0];

            return (
              <React.Fragment>
                <Helmet>
                  <title>
{title}
{' '}
- Juliana Futter
</title>
                </Helmet>

                <ImageWrapper>
                  {images.map((rawImage) => {
                    const image = imageForSize(rawImage, {
                      maxWidth: 4000,
                      maxHeight: 2000,
                    });
                    return <img src={image} alt={rawImage.altText} />;
                  })}
                </ImageWrapper>

                <Info isOpen={this.state.detailsIsOpen}>
                  <Footer>
                    <Description>
                      {title}
{' '}
– £
{variants[0].price}
                    </Description>
                    {descriptionHtml && (
                      <DetailsButton onClick={this.toggleDetails}>
                        {this.state.detailsIsOpen ? 'Close' : 'Details'}
                      </DetailsButton>
                    )}

                    <Button
                      disabled={!context.cart}
                      onClick={() => context.addToCart(variants[0].id, 1)}
                    >
                      Add to Cart
                    </Button>
                  </Footer>

                  <Details
                    dangerouslySetInnerHTML={createMarkup(descriptionHtml)}
                  />
                </Info>
                <Overlay
                  onClick={this.toggleDetails}
                  isVisible={this.state.detailsIsOpen}
                />
              </React.Fragment>
            );
          }}
        </ShopifyContext.Consumer>
      </Container>
    );
  }
}

const Container = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 1;
  padding-top: 56px;

  @media (min-width: 900px) {
    padding-top: 106px;
    font-size: 26px;
    min-height: 100vh;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  background-color: #f2f2f2;
  height: 100vw;
  max-height: 70vh;
  /* padding: 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: fixed; */

  img {
    max-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (min-width: 900px) {
    max-height: 100%;
    height: calc(100vh - 217px);
  }
`;

const Info = styled.div`
  background-color: #ffffff;
  width: 100%;

  @media (min-width: 900px) {
    position: fixed;
    z-index: ${props => (props.isOpen ? '300' : '10')};
    top: 100%;
    transform: translateY(${props => (props.isOpen ? '-100%' : '-111px')});
    transition: transform 300ms;
  }
`;

const Overlay = styled.div`
  display: none;

  @media (min-width: 900px) {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(220, 220, 220, 0.8);
    z-index: 250;
    cursor: pointer;
    transition: opacity 500ms;
    opacity: ${props => !props.isVisible && 0};
    pointer-events: ${props => !props.isVisible && 'none'};
  }
`;

const Footer = styled.footer`
  width: 100%;
  background-color: white;
  padding: 20px;
  border-bottom: 1px solid #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 900px) {
    padding: 40px;
    border-bottom: none;
  }
`;

const Description = styled.p`
  margin: 0;
  font-size: 26px;
  margin-right: 20px;
`;

const DetailsButton = styled.button`
  padding: 0;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  background: none;
  border: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #b3b3b3;
  cursor: pointer;
  transition: color 300ms;

  &:hover {
    color: #333333;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

const Button = styled.button`
  font-family: inherit;
  font-size: inherit;
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 9px 10px 11px;
  transition: background-color 300ms, color 300ms;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;

  opacity: ${props => props.disabled && 0.5};
  pointer-events: ${props => props.disabled && 'none'};

  &:hover {
    background-color: #333333;
    color: white;
  }

  @media (min-width: 900px) {
    padding: 9px 15px 11px;
    margin: -9px -15px -11px;
    width: 160px;
  }
`;

const Details = styled.div`
  padding: 20px 20px 97px;
  padding-bottom: calc(75px + 22px + env(safe-area-inset-bottom));
  color: #808080;

  p {
    margin: 0 0 10px;

    &:last-child {
      margin: 0;
    }
  }

  @media (min-width: 900px) {
    color: #333333;
    text-align: center;
    padding: 25px 40px 80px;

    p {
      max-width: 520px;
      margin: 0 auto 20px;

      &:last-child {
        margin: 0 auto;
      }
    }
  }
`;
